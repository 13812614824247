import React, { useState } from 'react';
import AWS from 'aws-sdk'
import { Button, notification, Space, Card } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

const S3_BUCKET = 'ks-app-media-01';
const REGION = 'us-east-2';

AWS.config.update({
    accessKeyId: 'AKIAVE75HHAJUGIXVXOB',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

const UploadImageToS3WithNativeSdk = (props) => {
    console.log(props);
    const [path, setPath] = props.path;
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = (file) => {

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: props.path + "/" + props.fileType + "/" + file.name
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
                console.log(progress);
                if (Math.round((evt.loaded / evt.total) * 100) >= 100) {
                    props.refreshPage();
                }
            })

            .send((err) => {
                if (err) console.log(err)
            })

    }

    return <Card bordered>
        <Space>
        <div>
            <input type="file" 
            style={{width : "200px"}}
            accept={props.fileType == 'bilder'? "image/*" : "application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"} 
            onChange={handleFileInput} 
            />
            {progress > 0 ? progress == 100 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : progress + "%" : ""}
        </div>
        <Button onClick={() => uploadFile(selectedFile)}>Hochladen</Button>
    </Space>
    </Card>
}

export default UploadImageToS3WithNativeSdk;