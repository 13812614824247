import React, { useState, useEffect, useRef } from 'react';
import { getBreeders } from '../util/APIUtils';
import {
    Select,
} from 'antd';

function BreederSelectForm({ value, onChange }) {
    const [itemList, setItemList] = useState([])
    const [loading, setLoading] = React.useState(false);

    const fetchBreeders = React.useCallback(async () => {
        let promise;
        promise = getBreeders();
        setLoading(true)
        if (!promise) {
            return;
        }

        promise.then(response => {
            setItemList(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        fetchBreeders()
    }, [fetchBreeders]);


    const handleChange = selectedItem => {
        onChange(parseInt(selectedItem));
    };

    const filteredOptions = itemList
    return (
        <>
            <Select
            showSearch
                mode="single"
                loading={loading}
                placeholder= {"Gegenstände auswählen"}
                defaultValue={value ? parseInt(value) : null}
                onChange={handleChange}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                style={{ width: '100%' }}
            >
                {filteredOptions.map(item => (
                    <Select.Option key={item.id} value={item.id} label={item.firstName + " " + item.lastName}>
                        {item.firstName}  {item.lastName}
                    </Select.Option>
                ))}
            </Select>

        </>
    );
}

export default BreederSelectForm