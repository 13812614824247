import React, { useState } from 'react';
import shortid from 'shortid';
import {
    Badge,
    Card,
    Descriptions,
    Button,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Space,
    Tabs,
    message
} from 'antd';
import FgCalculation from './FgCalculation';
import EditableFgBudget from './EditableFgBudget';
import EditableGridFg from './EditableGridFg';
import { Spa } from '@mui/icons-material';
import { getFgAccountingItems, updateFgBudget } from '../util/APIUtils';
import FgActionButtons from './FgActionButtons';
import FgAmountSummary from './FgAmountSummary';

function DetailFgContainer(props) {
    const [loading, setLoading] = useState(false);
    const { currentUser, year } = props
    const [fgDetails, setFgDetails] = useState(props.fgDetails)
    const fgCalculationList = [...fgDetails.fachGroupCalculationList]
    const currentPhase = currentUser.projectYear.currentPhase;
    const [accountingItems, setAccountingItems] = React.useState([]);
    const [editing, setEditing] = useState(false)
    const [plan, setPlan] = useState(props.fgDetails.activityPlan)
    const [report, setReport] = useState(props.fgDetails.activityReport)

    const propFgDetails = { ... fgDetails}

    console.log(fgDetails)


    const saveFgBudget = (fgBudget) => {
        setEditing(false)

        let promise;
        promise = updateFgBudget(fgBudget);

        if (!promise) {
            return;
        }
        setLoading(true)

        promise.then(response => {
            if (!response) {
                message.warn("Jahresdaten vorhanden")
            }
            setFgDetails(response)
            setLoading(false)
            
            message.success("Aktualisiert!")
        }).catch(error => {
            setLoading(false)
        });

    }

    const loadAccountingItems = React.useCallback(async () => {

        if(accountingItems.length > 0) return;
        
        let promise;
        promise = getFgAccountingItems();

        if (!promise) {
            return;
        }
        setLoading(true);

        promise.then(response => {
            setAccountingItems(response)
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    React.useEffect(() => {
        loadAccountingItems();
    }, [loadAccountingItems]);

    fgCalculationList.forEach(function (entry) {
        entry.key = shortid.generate();
    });

    const startEdit = () => {
        setEditing(true);
    }

    const onSave = (value, field) => {
        let fgBudgetCopy = Object.assign({}, fgDetails);
        fgBudgetCopy.activityPlan = plan;
        fgBudgetCopy.activityReport = report;
        saveFgBudget(fgBudgetCopy)
    }

    const handleChange = (e, field) => {
        if(field === 'plan') {
            setPlan(e.target.value);
        } else if(field === "report") {
            setReport(e.target.value);
        }
    }

    const onCancel = () => {
        setEditing(false)
    }

    return (
        <div>
            <Card
            bordered
            loading={loading}
            extra={[fgDetails.isFgPlanEditable || fgDetails.isFgReportEditable ?
                <Space> {editing ? <Space> <Button onClick={onSave} key="33" type="primary" >Speichern
                </Button><Button onClick={onCancel} key="34" type="primary" danger>Absagen</Button></Space> :
                    <Button key="334" type="primary" onClick={startEdit}>Bearbeiten</Button>}</Space> : "",]}
            >
            <Descriptions
                size="small"
                bordered
                column={2}
                
                style={{
                    marginBottom: 0
                }}
            >
                <Descriptions.Item label="Geplante Fachgruppenarbeit" span={2} style={{ "width": "250px" }}>
                    {editing && fgDetails.isFgPlanEditable ? <Input.TextArea name="fgPlan" defaultValue={plan} onChange={(value) => handleChange(value, "plan")} /> : plan}
                </Descriptions.Item>

                <Descriptions.Item label="Diesjährige Fachgruppenarbeit" span={2}>
                    {editing && fgDetails.isFgReportEditable ? <Input.TextArea name="fgReport" defaultValue={report} onChange={(value) => handleChange(value, "report")}/> : report}
                </Descriptions.Item>

            </Descriptions>
            <br></br>
            {/* {!editing? 
            <FgCalculation fgDetails={fgDetails} /> : */}

            {/* <EditableFgBudget fgDetails={fgDetails} data={fgCalculationList} fgBudgetId={fgDetails.id} fgBudgetPhase={props.currentPhase} /> */}
            <EditableGridFg year={year} fgBudgetId={propFgDetails.id} isPlanEditable={propFgDetails.isFgPlanEditable} isReportEditable={propFgDetails.isFgReportEditable} accountingItems={accountingItems} />

            {/* } */}
            </Card>
            <FgAmountSummary id={fgDetails.id} />
            {/* {<FgActionButtons fgBudgetInfo={fgDetails} currentUser={currentUser} reload={props.reload}/>} */}
        </div>
    )
}

export default DetailFgContainer
