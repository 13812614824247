import React, { Component } from 'react'
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Tag,
    Space,
    Button,

} from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import { getYears, getZslData, getZslActualReportLink } from '../util/APIUtils';
import { dynamicSort, formatCurrency } from '../util/Helpers';

import './zsl.css'
import {

    FileExcelOutlined,
    FileWordOutlined
} from '@ant-design/icons';
import { lightGreen } from '@mui/material/colors';



export class ZslAntrag extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: null,
            projectYears: [],
            zslData: [],
            isLoading: false,
        };
    }

    fetchYears() {
        this.setState({ isLoading: true });
        let promise;
        promise = getYears();
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({ projectYears: response.reverse() })
            this.setState({ isLoading: false });

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }

    fetchZslData(year) {
        this.setState({ isLoading: true });
        let promise;
        promise = getZslData(year);
        if (!promise) {
            return;
        }

        promise.then(response => {
            const grandTotal = response.reduce((totals, item) => {
                totals.totalProjectAmount += item.totalProjectAmount || 0;
                totals.totalExistingProjects += item.totalExistingProjects || 0;
                totals.totalNewProjects += item.totalNewProjects || 0;
                totals.totalFgBudget += item.totalFgBudget || 0;
                totals.totalProjectAmountWithFg += item.totalProjectAmountWithFg || 0;
                return totals;
            }, { totalProjectAmount: 0, totalExistingProjects: 0, totalNewProjects: 0, totalFgBudget: 0, totalProjectAmountWithFg : 0 });
    
            response.push({
                breeder: 'Gesamt',
                totalProjectAmount: grandTotal.totalProjectAmount,
                totalExistingProjects: grandTotal.totalExistingProjects,
                totalNewProjects: grandTotal.totalNewProjects,
                totalFgBudget: grandTotal.totalFgBudget,
                totalProjectAmountWithFg : grandTotal.totalProjectAmountWithFg
            });
    
            this.setState({
                zslData: response,
                isLoading: false
            });

        }).catch(error => {
            console.log(error)
            this.setState({ isLoading: false });
        });
    }

    handleYearInput = (val) => {
        console.log(val);
        this.setState({
            year: val
        })

        console.log(this.state.year);
        this.fetchZslData(val);
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this
                .props
                .history
                .push("/login");
        }

        this.fetchYears();

    }

    getReport = () => {
        const win = window.open(getZslActualReportLink(this.state.year), '_blank');
        if (win != null) {
            win.focus();
        }
      
    }



    render() {

        let columns = [
            {
                title: "Züchter",
                dataIndex: 'breeder',
                key: 'breederShortName',
                width: 200,
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => {
                    return (
                        record["farmProfileName"] ? <span><b>{record["breeder"]}</b>, {record["farmProfileName"]}</span>:  <span><b>{record["breeder"]}</b></span>
                    )
                },
            },
            {
                title: "Mittel für Projekte",
                dataIndex: 'totalProjectAmount',
                key: 'projectType',
                align: "right",
                width: 160,
                render: (text, row, index) => {
                    return (
                        <span><b>
                            {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                        </b>
                        </span>
                    )
                },

                // width: 300,
            },
            {
                title: "davon für laufende Projekte",
                dataIndex: 'totalExistingProjects',
                key: 'projectType',
                align: "right",
                width: 160,
                render: (text, row, index) => {
                    return (
                        <span><b>
                            {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                        </b>
                        </span>
                    )
                },

                // width: 300,
            },
            {
                title: "davon für Neuprojekte",
                dataIndex: 'totalNewProjects',
                key: 'projectType',
                align: "right",
                width: 160,
                render: (text, row, index) => {
                    return (
                        <span><b>
                            {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                        </b>
                        </span>
                    )
                },

                // width: 300,
            },
            {
                title: "Fachgruppentätigkeit",
                dataIndex: 'totalFgBudget',
                key: 'cropName',
                align: "right",
                width: 160,
                render: (text, row, index) => {
                    return (
                        <span><b>
                            {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                        </b>
                        </span>
                    )
                },
            },
            {
                title: "Gesamt",
                dataIndex: 'totalProjectAmountWithFg',
                key: 'cropName',
                align: "right",
                width: 160,
                render: (text, row, index) => {
                    return (
                        <span><b>
                            {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                        </b>
                        </span>
                    )
                },

            }
        
        ];

        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={"ZSL-Antrag"}
                // extra={<Button type='primary'>Add</Button>}
                // subTitle={this.state.farmProfile.name}
                />
                <Card bordered={true} headStyle={{ padding: "0" }} loading={this.state.isLoading} extra={this.state.year ? <Space> <FileWordOutlined style={{ fontSize: '28px', color: '#08c' }} onClick={this.getReport} />  </Space> : ""}>

                    <Space>
                        <Select style={{ width: '140px' }} onChange={this.handleYearInput} defaultValue={this.state.year} placeholder={"Jahr"}>
                            {this.state.projectYears.map(item => (
                                <Select.Option key={item.projectYear} value={item.projectYear}>
                                    {item.projectYear}
                                </Select.Option>
                            ))}
                        </Select>
                    </Space><Divider></Divider>
                    {this.state.zslData.length > 0 && <span>
                        <Table
                            size='small'
                            rowClassName={(record, index) => {
                                if (record.breeder === 'Gesamt') {
                                    return 'grand-total-row';
                                }
                                return ''; // Return empty string or another class for other rows
                            }}
                            columns={columns}
                            style={{ fontSize: '10px' }}
                            dataSource={this.state.zslData}
                            loading={this.state.isLoading}
                            pagination={false} />
                            <br></br>
                        <h3>Berichte der Projekte im Einzelnen</h3>

                        {this.state.zslData.map(item => (

                            <Descriptions  bordered column={1}>
                                <Descriptions.Item label="Projektleiter" span={3} labelStyle={{'width': '150px'}}>{item.breeder}</Descriptions.Item>
                                <Descriptions.Item label="Zuchtstandort" span={3}>{item.farmProfileName} <br/> {item.breederAddress}</Descriptions.Item>
                                <Descriptions.Item span={3}>
                                <div>
                                    <List
                                        itemLayout="horizontal"
                                        style={{ 'backgroundColor': '#F7F7F7' }}
                                        dataSource={item.breederCropForecastInfos}
                                        loading={this.state.isLoading}
                                        renderItem={item => (
                                            <div>
                                                <h4 style={{'paddingLeft': '10px'}}><i>{item.cropName}</i></h4>
                                                <List.Item>
                                                    <List.Item.Meta
                                                        // title={<Card title="Aúsblick" size='small' >
                                                        //     {item.outlook}
                                                        // </Card>}
                                                        description={<span>
                                                            
                                                            <Card title="Hintergrund" size='small'>
                                                                {item.background}
                                                            </Card>
                                                            <Card title="Ausblick" size='small'>
                                                                {item.outlook}
                                                            </Card></span>
                                                        }
                                                    />

                                                </List.Item>
                                            </div>

                                        )}

                                    />


                                </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="" span={3}>
                                    <Descriptions  bordered column={1}>
                                        <Descriptions.Item label={"Beantragte Mittel für " + item.farmProfileName} labelStyle={{ fontWeight: 'bold' }} span={3}></Descriptions.Item>
                                        <Descriptions.Item label={"Beantragte Mittel für projekte"} contentStyle={{ textAlign: 'right' }} span={3}>{formatCurrency(item.totalProjectAmount)}</Descriptions.Item>
                                        <Descriptions.Item label={"davon mittel für laufende projekte"} contentStyle={{ textAlign: 'right' }} span={3}>{formatCurrency(item.totalExistingProjects)}</Descriptions.Item>
                                        <Descriptions.Item label={"davon mittel für Nue projekte"} contentStyle={{ textAlign: 'right' }} span={3}>{formatCurrency(item.totalNewProjects)}</Descriptions.Item>
                                        <Descriptions.Item label={"Beantragte Mittel für Fachgruppentätigkeit"} contentStyle={{ textAlign: 'right' }} span={3}>{formatCurrency(item.totalFgBudget)}</Descriptions.Item>
                                        <Descriptions.Item label={"Insgesamt vom standort beantragte mittel"} contentStyle={{ textAlign: 'right' }} labelStyle={{ fontWeight: 'bold', backgroundColor: lightGreen }} span={3}>{formatCurrency(item.totalProjectAmountWithFg)}</Descriptions.Item>
                                        <Descriptions.Item label={"Beantragte mittel für externe versuchsanbauprojekte (z.B. Kandidatensichtungen)"} contentStyle={{ textAlign: 'right' }} span={3}>{formatCurrency(item.totalVaExternBudget)}</Descriptions.Item>
                                    </Descriptions>
                                </Descriptions.Item>
                                </Descriptions>
                    ))}
                            </span>}

                    </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(ZslAntrag));
