import React, { Component } from 'react';
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Space,
    Tabs
} from 'antd';


import { EnvironmentTwoTone, TabletTwoTone } from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import { getFarmProfileById, updateFarmProfile, getAllBreeders, updateFarmBreeder, getFarmLocationsForId, deleteFarmProfileById } from '../util/APIUtils';
import { withRouter } from 'react-router-dom';
import GeoSuggest from 'antd-geosuggest';
import { POLL_LIST_SIZE } from '../constants';
import { Button, Icon, Form } from 'antd';
import './FarmProfile.css'
import PickBreeder from './locations/PickBreeder';
import { withTranslation } from "react-i18next";
import i18next from 'i18next';
import TextArea from 'antd/lib/input/TextArea';
import NotificationUnAuthorized from '../common/openNotification';
import openNotification from '../common/openNotification';
import DeleteWithConfirm from '../common/DeleteWithConfirm';
import FarmProfileDocuments from './documents/FarmProfileDocuments';

const { TabPane } = Tabs;

class FarmProfileDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            farmProfile: {},
            projects: [],
            breeders: [],
            selectedBreeders: [],
            page: 0,
            size: 10,
            totalElements: 0,
            totalPages: 0,
            last: true,
            isLoading: false,
            breederBlock: false,
            farmProfileId: null,
            visible: false,
            editMode: false,
            farmProfileName: null,
            farmProfilePhone: null,
            farmprofileEditing: {},
            currentUser: props.currentUser,
            id: this.props.match.params.id
        };
    }

    updateData = res => {
        if (res.length > 0) {
            let nAddr = res[0].gmaps.address_components;
            const addr = nAddr.reduce((seed, { long_name, types }) => (types.forEach(t => seed[t] = long_name), seed), {});
        }
    }

    loadLocations() {
        console.log('Loading Locations')
        let promise;
        promise = getFarmLocationsForId(this.state.id);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            this.loadBreeders();
            this.setState({
                locations: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadBreeders() {
        console.log('Loading Breeders')
        let promise;
        promise = getAllBreeders();

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response);
            let brdrs = response.map(a => a.fullName);
            let vals = [];
            for (var item of response) {
                vals.push({
                    'id': item.id,
                    'fullName': item.firstName + " " + item.lastName
                });
            }
            this.setState({
                breeders: vals,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    deleteFarmProfile = () => {
        let promise;
        promise = deleteFarmProfileById(this.state.id);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response);
            if (!response) {
                this.setState({ isLoading: false })
                openNotification("error", "Abhängigkeiten bestehen. Diese Ressource kann nicht gelöscht werden!");
                return;
            }

            this.loadFarmProfile();
            this.props.history.push("/farmprofile/list");

        }).catch(error => {
            if (error?.message === "Forbidden") {
                console.log("here")
                openNotification("error", "Sie sind nicht berechtigt, diese Aktion auszuführen! Administrator kontaktieren")
            }
            console.log(error);
            this.setState({ isLoading: false })
        });
    }

    reload = () => {
        this.setState({ isLoading: false })
        // window.location.reload();
    }

    loadFarmProfile() {
        console.log('Loading Farm profile')
        let promise;
        promise = getFarmProfileById(this.state.id);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response);
            let vals = [];
            for (var item of response.breeders) {
                vals.push(item.id
                );
            }
            this.setState({
                farmProfile: response,
                isLoading: false,
                selectedBreeders: vals
            });
            // this.loadLocations(id);


            // let projects = this.state.farmProfile.breeders.map(e => e.projects);
            // let allProjects = [].concat.apply([], projects);

            this.setState({
                projects: []
            })
        }).catch(error => {
            console.log("Error occured");
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {

        this.loadBreeders();
        this.loadFarmProfile();
    }

    componentDidUpdate(nextProps) {
        const { id } = nextProps.match.params
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                farmProfile: {},
                isLoading: false,
                farmProfileId: this.state.id
            });
            // this.loadLocations(id);
            this.loadFarmProfile();
            this.loadBreeders();

        }
    }

    setBreedersFromModal = breeders => {
        let vals = [];
        let promise;

        for (var item of breeders) {
            vals.push({
                "farmProfileId": this.state.farmProfile.id,
                "breederId": item,
                "ksBreeder": true
            });
        }

        console.log(vals)
        promise = updateFarmBreeder(this.state.farmProfile.id, vals);
        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response);
            this.loadFarmProfile(this.state.farmProfile.id);
            this.setState({
                visible: false,
                isLoading: false
            })
            // window.location.reload();
        }).catch(error => {
            console.log(error);
            this.setState({ isLoading: false })
        });
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    onEdit = (e) => {
        this.setState({
            editMode: e
        })
    }

    onSave = e => {

        console.log(this.state.farmProfile)
        this.setState({
            editMode: false
        })
        console.log(this.state.farmProfileName ? this.state.farmProfileName : this.state.farmProfile.name)
        console.log(this.state.farmProfilePhone ? this.state.farmProfilePhone : this.state.farmProfile.phone)

        let promise = updateFarmProfile(this.state.farmProfile);
        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response);
            this.loadFarmProfile(this.state.farmProfile.id);
            this.setState({
                visible: false,
                isLoading: false
            })
        }).catch(error => {
            console.log(error);
            this.setState({ isLoading: false })
        });
    }
    onInputchange = (event) => {
        this.setState({ farmProfile: { ...this.state.farmProfile, [event.target.name]: event.target.value } });
    }

    onInputchangeOther = (event) => {
        this.setState({ farmProfile: { ...this.state.farmProfile, ["type"]: event } });
    }

    getFarmProfileType = (typeName) => {
        if (typeName == 1) {
            return "Firma"
        } else if (typeName == 2) {
            return "Frau";
        } else if (typeName == 3) {
            return "Herr";
        }

    }

    render() {
        const { t } = this.props;

        // const { id } = this.props.match.params
        // this.loadFarmProfile(id);
        // this.loadBreeders();
        let placeHolder = "Search Farm profiles";
        let bInfo = this.state.farmProfile;
        let officeUser = this.state.currentUser ? this.state.currentUser.officeUser : null;
        console.log(this.state.farmProfile)
        let buttonBar = this.state.editMode ?
            <Space>
                <Button key="334" type="primary" onClick={this.onSave}>{t("general.save")}</Button>
                <Button key="334" onClick={() => this.onEdit(false)}>{t("general.cancel")}</Button>
            </Space>
            :
            <Space>
                <Button key="334" type="primary" onClick={() => this.onEdit(true)}>{t("general.edit")}</Button>
                <DeleteWithConfirm confirm={this.deleteFarmProfile} />
            </Space>
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    extra={[officeUser ? buttonBar : ""]}
                    title={t("farmprofile.farm_profile")}
                    subTitle={this.state.farmProfile.name} />
                <Tabs defaultActiveKey="1" className="custom-tab-style">
                    <TabPane tab="Profil" key="1">
                        <Card bordered={true} loading={this.state.isLoading} headStyle={{ padding: "0" }}>
                            <Descriptions
                                title={this.state.farmProfile.name}
                                size="small"
                                column={2}
                                bordered="true"
                                style={{
                                    marginBottom: 20
                                }}>
                                <Descriptions.Item label={t("general.name")} ><b>{this.state.editMode ? <Input onChange={this.onInputchange} name="name" defaultValue={this.state.farmProfile.name} /> : this.state.farmProfile.name}</b></Descriptions.Item>
                                <Descriptions.Item label={t("general.type")}>{this.state.editMode ? <Select onChange={this.onInputchangeOther} defaultValue={this.state.farmProfile.type} name="type" style={{ width: 120 }} >
                                    <Option value={1}>{"Firma"}</Option>
                                    <Option value={2}>{"Frau"}</Option>
                                    <Option value={3}>{"Herr"}</Option>
                                </Select> : this.getFarmProfileType(this.state.farmProfile.type)}</Descriptions.Item>
                                <Descriptions.Item label={t("general.house_no")}>{this.state.editMode ? <Input onChange={this.onInputchange} name="houseNo" defaultValue={this.state.farmProfile.houseNo} /> : this.state.farmProfile.houseNo}</Descriptions.Item>
                                <Descriptions.Item label={t("general.address")}>{this.state.editMode ? <Input.TextArea onChange={this.onInputchange} name="address" defaultValue={this.state.farmProfile.address} /> : this.state.farmProfile.address}</Descriptions.Item>
                                <Descriptions.Item label={t("general.phone")}>{this.state.editMode ? <Input onChange={this.onInputchange} name="phone" defaultValue={this.state.farmProfile.phone} /> : this.state.farmProfile.phone}</Descriptions.Item>
                                {/* <Descriptions.Item label={t("general.email")}>{this.state.editMode ? <Input onChange={this.onInputchange} name="farmProfileEmail" defaultValue={this.state.farmProfile.email} /> : this.state.farmProfile.email}</Descriptions.Item> */}
                                <Descriptions.Item label={t("general.website")}>
                                    {this.state.editMode ? (
                                        <Input onChange={this.onInputchange} name="website" defaultValue={this.state.farmProfile.website} />
                                    ) : (
                                        <a href={this.state.farmProfile.website} target="_blank" rel="noopener noreferrer">
                                            {this.state.farmProfile.website}
                                        </a>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={t("farmprofile.social_media")}>{this.state.editMode ? <Input onChange={this.onInputchange} name="socialLinks" defaultValue={this.state.farmProfile.socialLinks} /> : this.state.farmProfile.socialLinks}</Descriptions.Item>
                                <Descriptions.Item label={t("general.description")}>{this.state.editMode ? <TextArea rows={3} onChange={this.onInputchange} name="description" defaultValue={this.state.farmProfile.description} /> : this.state.farmProfile.description}</Descriptions.Item>
                            </Descriptions>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={24}>
                                    <Card bordered={true} title={t("general.breeders")} extra=
                                        {[officeUser ? <PickBreeder
                                            modalVisible={this.state.visible}
                                            setBreeders={this.setBreedersFromModal}
                                            breederList={this.state.breeders}
                                            onExit={this.reload}
                                            existingBreeders={this.state.selectedBreeders} /> : ""]} >
                                        <List
                                            dataSource={this.state.farmProfile.breeders}
                                            renderItem={item => {
                                                let linkText = `/breeders/profile/${item.id}/0`;
                                                return (<List.Item key={"br" + item.id} >
                                                    <List.Item.Meta
                                                        avatar={
                                                            <TabletTwoTone />
                                                        }

                                                        title={<Link
                                                            to={{
                                                                pathname: linkText,

                                                            }}>{item.firstName}  {item.lastName}</Link>}

                                                        // title={<a href={linkText}>{item.firstName}  {item.lastName}</a>}
                                                        description={item.fullName}
                                                    />
                                                </List.Item>)
                                            }} />
                                    </Card>
                                </Col>

                            </Row>





                            <div style={{
                                // marginLeft: 10
                            }}>

                            </div>
                        </Card>
                    </TabPane>
                    <TabPane tab="Documents" key="2">
                        {/* Content for the Documents tab */}
                        <Card bordered={true}>
                            <FarmProfileDocuments currentUser={this.state.currentUser} fpId={this.state.farmProfile.id} fpName={this.state.farmProfile.name} />
                        </Card>
                    </TabPane>
                </Tabs>
            </div>

        );
    }
}
export default withTranslation()(withRouter(FarmProfileDetail));
