import React, { Component } from 'react';
import { Button, Collapse, Select, Tabs, Empty, Switch, Row, Col, message, Space, notification, Popconfirm, Tag, Card } from 'antd';
import { EditFilled, SecurityScanTwoTone } from '@ant-design/icons';
import { submitProject, approveProjectFg, approveProjectBoard, getProcessTasks, approveFunding, deleteProjectYear, submitProjectStatus } from '../../util/APIUtils';
import { getCurrentStatusFromTasks, getCurrentTask } from '../../util/Helpers'
import Plan from './Plan';
import Calculation from './Calculation';
import Overview from './Overview';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import StatusTimeline from './StatusTimeline';
import StatusTag from './StatusTag';
import ActionButtons from './ActionButtons';
import ProjectReport from './ProjectReport';
import ProjectComments from './ProjectComments';
import Lines from '../lines/Lines';
import PlanVa from './PlanVa';
import PlanGl from './PlanGl';
import PlanEz from './PlanEz';
import ProjectResources from './ProjectResources';
import { isPlanEditable, isReportEditable } from '../../util/ProjectStatusHelper';
import SortenBiographie from '../../sortenbiographie/SortenBiographie';
import ReportEz from '../project_report/ReportEz';
import ReportContainer from '../project_report/ReportContainer';
import ValidationContainer from '../../validations/ValidationContainer';
import CommentsList from '../../fg_review/CommentList';
// import Satz from '../../satz/Satz';

const { Panel } = Collapse;
const { TabPane } = Tabs;


class DetailContainer extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            activeTab: props.activeTab,
            currentUser: props.currentUser,
            loading: false,
            editing: false,
            year: props.year,
            projectInfo: props.projectInfo,
            projectDetails: props.projectDetails,
            projectDetailYear: props.projectDetails.find(o => o.year === props.year),
            accountingItems: props.accountingItems,
            examinationItems: props.examinationItems,
            lineSelections: props.lineSelections,
            breedingMethods: props.breedingMethods,
            approvalResponse: false,

        };
    }

    setEditing = (val) => {
        this.setState(
            { editing: val }
        );
    }

    submitMyProject = (description, review) => {
        let promise;
        promise = submitProjectStatus(this.state.projectDetailYear.id, description, review);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                isLoading: false
            })
            this.getProcessInfo(this.state.projectDetailYear.processId)
            this.props.reloadProject({ 'year': this.props.year, "tab": 1 });
        }).catch(error => {
            console.log(error);
            this.setState({ isLoading: false });
        });
    }

    approveProject = (val, type, description, amount, review) => {

        let promise;
        if (type === "fg") {
            promise = approveProjectFg(this.state.projectDetailYear.id, val, description, amount, review);
        }
        if (type === "fg_re") {
            promise = approveProjectFg(this.state.projectDetailYear.id, val, description, amount, review);
        } else if (type === "board") {
            promise = approveProjectBoard(this.state.projectDetailYear.id, val, description, amount);
        } else if (type === "report") {
            promise = approveFunding(this.state.projectDetailYear.id, val, description);
        }

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });

        promise.then(response => {
            if (response == null) {
                return;
            }
            console.log("Approved");

            this.setState({
                isLoading: false,
                approvalResponse: response
            })
            this.props.reloadProject({ 'year': this.props.year, "tab": 1 });

            message.success("Projekt bei FG eingereicht")
            if (!this.state.approvalResponse) {
                message.warn("Sie sind nicht berechtigt, diese Aufgabe zu genehmigen")
            }

            this.getProcessInfo(this.state.projectDetailYear.processId);

        }).catch(error => {
            console.log(error)
            message.warn("Sie sind nicht berechtigt, diese Aufgabe zu genehmigen")
            this.setState({ isLoading: false })
        });
    }

    getProcessInfo = (processId) => {
        let promise;
        promise = getProcessTasks(processId);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            this.setState({
                isLoading: false
            })
            // this.props.reloadProject({ 'year': this.props.year, "tab": 1 });
        }).catch(error => {
            console.log(error)
            this.setState({ isLoading: false })
        });
    }

    deleteProjectYearData = () => {
        let promise;
        promise = deleteProjectYear(this.state.projectDetailYear.id);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            if (response) {
                notification.success({ message: "Kultursaat", description: "Projektjahr erfolgreich gelöscht", placement: 'topLeft' })
                this.props.reloadProject({ 'year': this.props.year, "tab": 1 });
            } else {
                notification.error({ message: "Kultursaat", description: "Projektjahr kann nicht gelöscht werden, Projekt nicht in ENTWURF", placement: 'topLeft' })
            }
            this.setState({
                isLoading: false
            })

        }).catch(error => {
            console.log(error)
            notification.error({ message: "Kultursaat", description: "Projektjahr kann nicht gelöscht werden", placement: 'topLeft' })
            this.setState({ isLoading: false })
        });
    }

    getPlan(projectType, currentStatus, isPlanEditable) {
        console.log(currentStatus)
        if (projectType == "NZ") {
            return <Plan year={this.props.year}
                projectInfo={this.state.projectInfo}
                projectDetail={this.state.projectDetailYear}
                editing={this.state.editing}
                lineSelections={this.state.lineSelections}
                breedingMethods={this.state.breedingMethods}
                reloadProject={this.props.reloadProject}
                workFlowState={currentStatus.name ? currentStatus.name : "NONE"}
                isPlanEditable={isPlanEditable}
                setEditingMaster={this.setEditing}
                currentUser={this.state.currentUser}
            />
        } else if (projectType == "VA") {
            return <PlanVa year={this.props.year}
                projectInfo={this.state.projectInfo}
                projectDetail={this.state.projectDetailYear}
                editing={this.state.editing}
                lineSelections={this.state.lineSelections}
                breedingMethods={this.state.breedingMethods}
                reloadProject={this.props.reloadProject}
                workFlowState={currentStatus.name ? currentStatus.name : "NONE"}
                isPlanEditable={isPlanEditable}
                setEditingMaster={this.setEditing}
                currentUser={this.state.currentUser}
            />
        }
        else if (projectType == "GL") {
            return <PlanGl year={this.props.year}
                projectInfo={this.state.projectInfo}
                projectDetail={this.state.projectDetailYear}
                editing={this.state.editing}
                lineSelections={this.state.lineSelections}
                breedingMethods={this.state.breedingMethods}
                reloadProject={this.props.reloadProject}
                workFlowState={currentStatus.name ? currentStatus.name : "NONE"}
                isPlanEditable={isPlanEditable}
                setEditingMaster={this.setEditing}
                currentUser={this.state.currentUser}
            />
        }
        else if (projectType == "EZ") {
            return <PlanEz year={this.props.year}
            cropId={this.state.projectInfo.cropId}
                projectInfo={this.state.projectInfo}
                projectDetail={this.state.projectDetailYear}
                editing={this.state.editing}
                lineSelections={this.state.lineSelections}
                breedingMethods={this.state.breedingMethods}
                reloadProject={this.props.reloadProject}
                workFlowState={currentStatus.name ? currentStatus.name : "NONE"}
                isPlanEditable={isPlanEditable}
                setEditingMaster={this.setEditing}
                currentUser={this.state.currentUser}
            />
        }
    }

    getReport = (projectType, currentStatus, isReportEditable) => {
        if (projectType == "NZ") {
            return <ProjectReport
                year={this.props.year}
                currentUser={this.state.currentUser}
                projectInfo={this.state.projectInfo}
                projectDetail={this.state.projectDetailYear}
                breedingMethods={this.state.breedingMethods}
                lineSelections={this.state.lineSelections}
                editing={this.state.editing}
                reloadProject={this.props.reloadProject}
                workFlowState={currentStatus ? currentStatus.name : "NONE"}
                isReportEditable={this.state.projectDetailYear.reportEditable}
            />
        } else if (projectType == "VA") {
            return <ReportContainer year={this.props.year}
                projectInfo={this.state.projectInfo}
                projectDetailInfo={this.state.projectDetailYear}
                editing={this.state.editing}
                lineSelections={this.state.lineSelections}
                breedingMethods={this.state.breedingMethods}
                reloadProject={this.props.reloadProject}
                workFlowState={currentStatus.name ? currentStatus.name : "NONE"}
                isReportEditable={isReportEditable}
                setEditingMaster={this.setEditing}
            />
        } else if (projectType == "GL") {
            return <ReportContainer year={this.props.year}
                projectInfo={this.state.projectInfo}
                projectDetailInfo={this.state.projectDetailYear}
                editing={this.state.editing}
                lineSelections={this.state.lineSelections}
                breedingMethods={this.state.breedingMethods}
                reloadProject={this.props.reloadProject}
                workFlowState={currentStatus.name ? currentStatus.name : "NONE"}
                isReportEditable={isReportEditable}
                setEditingMaster={this.setEditing}
            />
        }
        else if (projectType == "EZ") {
            return <ReportContainer year={this.props.year}
                cropId={this.state.projectInfo.cropId}
                projectInfo={this.state.projectInfo}
                projectDetailInfo={this.state.projectDetailYear}
                editing={this.state.editing}
                lineSelections={this.state.lineSelections}
                breedingMethods={this.state.breedingMethods}
                reloadProject={this.props.reloadProject}
                workFlowState={currentStatus.name ? currentStatus.name : "NONE"}
                isReportEditable={isReportEditable}
                setEditingMaster={this.setEditing}
            />
        }
    }

    changeTab = (activeTab) => {
        console.log(activeTab)
        this.setState(
            {
                activeTab,
                editing: false
            }
        )
    }

    render() {
        const { t } = this.props;
        // console.log(this.state.projectDetailYear.submitted)
        let currentStatus = {};
        let currentTask = {};
        let currentActiveYear = this.state.currentUser.projectYear.projectYear;
        let showComments = true;
        if (currentActiveYear == this.props.year) {
            showComments = this.state.currentUser.projectYear.enableFgComments
        }
        

        console.log(showComments)

        if (this.props.year != "Überblick") {
            currentStatus = this.state.projectDetailYear != null ? getCurrentStatusFromTasks(this.state.projectDetailYear.projectDetailTasks) : null;
            currentTask = this.state.projectDetailYear != null ? getCurrentTask(this.state.projectDetailYear.projectDetailTasks) : null;
        }
        return (

            this.props.year != "Überblick" ?
                <div>
                    <Tabs type="card"
                        activeKey={this.state.activeTab.toString()}
                        onChange={this.changeTab}
                        tabBarExtraContent={this.props.year == currentActiveYear || this.props.year == currentActiveYear - 1 ? <Space>
                            {this.state.projectDetailYear.submitted ? <Tag color="green">Eingereicht</Tag> : <Tag color="orange">Nicht eingereicht</Tag>}
                            {this.state.projectDetailYear.planEditable ? <Space><Popconfirm
                                title="Möchten Sie das Projektjahr wirklich löschen?"
                                onConfirm={this.deleteProjectYearData}>
                                <Button type="danger" size='small'>Jahr löschen</Button>
                            </Popconfirm>
                            
                            </Space> : ""}
                            

                            {(this.state.projectDetailYear.planEditable || this.state.projectDetailYear.reportEditable)  ?
                            <ValidationContainer projectId={this.state.projectInfo.projectId} type="project" reloadProject={this.props.reloadProject}/> : ""}

                            <StatusTag
                                status={currentStatus} />
                            {/* {(["1","2"].includes(this.state.activeTab) ) && (this.state.projectDetailYear.reportEditable || this.state.projectDetailYear.planEditable)  ? 
                                <Switch
                                    defaultChecked={false}
                                    checkedChildren="Bearbeiten"
                                    unCheckedChildren="Bearbeiten"
                                    onChange={this.setEditing} /> : ""} */}
                            {/* <Switch
                                checkedChildren="Editing"
                                onChange={this.setEditing} /> */}
                        </Space> : ""}>
                        <TabPane tab="Plan" key="1">
                            {this.getPlan(this.state.projectInfo.projectType, currentStatus, this.state.projectDetailYear.planEditable)}
                        </TabPane>
                        <TabPane tab={t("general.calculation")} key="2">
                            <Calculation
                                year={this.props.year}
                                projectDetail={this.state.projectDetailYear}
                                accountingItems={this.state.accountingItems}
                                examinationItems={this.state.examinationItems}
                                editing={this.state.editing}
                                reloadProject={this.props.reloadProject}
                                workFlowState={currentStatus ? currentStatus.name : "NONE"}
                                isPlanEditable={this.state.projectDetailYear.planEditable}
                                isReportEditable={this.state.projectDetailYear.reportEditable}
                            />
                        </TabPane>
                        {/* {this.state.projectInfo.projectType == "VA" && <TabPane tab={"Satz"} key="3-1">
                            <Satz
                                satzType="plan"
                                year={this.props.year}
                                projectDetailId={this.state.projectDetailYear.id}
                                projectId={this.state.projectInfo.projectId}
                                projectReportId={this.state.projectDetailYear.projectReportInfo?.id || 0}
                                currentStatus={currentStatus}
                                isReportEditable={this.state.projectDetailYear.reportEditable}
                                projectInfo={this.state.projectInfo}
                            />
                        </TabPane>} */}
                        <TabPane tab={t("general.report")} key="3">
                            {this.getReport(this.state.projectInfo.projectType, currentStatus, this.state.projectDetailYear.reportEditable)}
                            {/* <ProjectReport
                                year={this.props.year}
                                currentUser={this.state.currentUser}
                                projectInfo={this.state.projectInfo}
                                projectDetail={this.state.projectDetailYear}
                                breedingMethods={this.state.breedingMethods}
                                lineSelections={this.state.lineSelections}
                                editing={this.state.editing}
                                reloadProject={this.props.reloadProject}
                                workFlowState={currentStatus ? currentStatus.name : "NONE"}
                                isReportEditable={this.state.projectDetailYear.reportEditable}
                            /> */}
                        </TabPane>

                        {this.state.projectInfo.projectType == "NZ" && <TabPane tab={t("general.lines")} key="4">
                            <Lines
                                year={this.props.year}
                                projectDetailId={this.state.projectDetailYear.id}
                                projectId={this.state.projectInfo.projectId}
                                projectReportId={this.state.projectDetailYear.projectReportInfo?.id || 0}
                                currentStatus={currentStatus}
                                isReportEditable={this.state.projectDetailYear.reportEditable}
                                projectInfo={this.state.projectInfo}
                            />
                        </TabPane>}
                        <TabPane tab={t("general.status_timeline")} key="5">
                            <Row gutter={[16, 16]}>
                                {/* <Col span={12} style={{ alignContent: "start" }}>
                                    <StatusTimeline tasks={this.state.projectDetailYear.projectDetailTasks} processId={this.state.projectDetailYear.processId} />
                                </Col> */}
                                <Col span={24}>
                                    {showComments &&
                                    <Card bordered title={"FG-Kommentare"}>
                                     <CommentsList currentUser={this.state.currentUser} itemId={this.state.projectDetailYear.id}/>
                                     </Card>
                                        // <ProjectComments
                                        //     processId={this.state.projectDetailYear.processId}
                                        //     taskId={currentTask == null ? null : currentTask.taskId}
                                        //     currentUser={this.state.currentUser}
                                        // />
                                        }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab={"Bilder & Dokumente"} key="6">
                            <ProjectResources projectId={this.state.projectInfo.projectId} year={currentActiveYear}></ProjectResources>
                        </TabPane>
                        {this.state.projectInfo.projectType == "NZ" && <TabPane tab={"Sortenbiographie"} key="7">
                            <SortenBiographie
                                projectId={this.state.projectInfo.projectId}
                                year={this.props.year}
                                reportEditable={this.state.projectDetailYear.reportEditable}
                                reloadProject={this.props.reloadProject}>

                            </SortenBiographie>
                        </TabPane>}

                    </Tabs><div >
                        {/* {this.state.projectDetailYear.processId && this.state.isPlanEditable && */}
                        { !this.state.editing && (["1", "2"].includes(this.state.activeTab)) &&
                            <ActionButtons
                                currentUser={this.state.currentUser}
                                task={getCurrentStatusFromTasks(this.state.projectDetailYear.projectDetailTasks)}
                                cropId={this.state.projectInfo.cropId}
                                breederId={this.state.projectInfo.breederId}
                                approveProject={this.approveProject}
                                submitMyProject={this.submitMyProject}
                            />}

                    </div>
                </div> : <Overview projectDetails={this.state.projectDetails} />
        );
    }
}

export default withTranslation()(withRouter(DetailContainer));