import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Row, Col, Typography } from 'antd';
import { resetPasswordForgot, verifyPasswordToken } from '../../util/APIUtils';
const { Paragraph } = Typography;

const ResetPasswordForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');

    useEffect(() => {
        const pathParts = props.location.pathname.split('/');
        const tokenIndex = pathParts.findIndex(part => part === 'token') + 1;
        if (tokenIndex > 0 && tokenIndex < pathParts.length) {
            setToken(pathParts[tokenIndex]);
        }
    }, [props.location.pathname]);

    const validatePassword = (rule, value) => {
        if (value && (value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/g.test(value))) {
            return Promise.reject('Das Passwort muss mindestens 8 Zeichen lang sein und ein Sonderzeichen enthalten.');
        }
        return Promise.resolve();
    };

    const handleSubmit = async () => {
        if (newPassword !== confirmPassword) {
            notification.error({
                message: 'Fehler',
                description: 'Die Passwörter stimmen nicht überein.',
            });
            return;
        }
        if (!token) {
            notification.error({
                message: 'Fehler',
                description: 'Token nicht gefunden.',
            });
            return;
        }

        setIsLoading(true);
        let userObj = {
            "password": newPassword,
            "resetToken": token
        };

        console.log(userObj);

        let promise = resetPasswordForgot(userObj);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setIsLoading(false);
            notification.success({
                message: 'Kultursaat',
                description: "Passwort erfolgreich geändert"
            });
            props.history.push("/login")

        }).catch(error => {
            setIsLoading(false);
            notification.error({
                message: 'Fehler',
                description: "Fehler beim Zurücksetzen des Passworts."
            });
        });
    };

    return (
        <Row justify="center">
            <Col xs={24} sm={12} md={8}>
                <br></br><br></br>
                <Form
                    onFinish={handleSubmit}
                    layout="vertical"
                    style={{ maxWidth: '300px', margin: '0 auto' }}
                >
                     <Paragraph>
                        Das Passwort muss mindestens 8 Zeichen lang sein und ein Sonderzeichen enthalten.
                    </Paragraph>
                    <Form.Item
                        label="Neues Passwort"
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Bitte geben Sie Ihr neues Passwort ein!' },
                            { validator: validatePassword }
                        ]}
                    >
                        <Input.Password onChange={e => setNewPassword(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="Passwort bestätigen"
                        name="confirmPassword"
                        rules={[{ required: true, message: 'Bitte bestätigen Sie Ihr neues Passwort!' }]}

                    >
                        <Input.Password onChange={e => setConfirmPassword(e.target.value)} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block loading={isLoading}>
                            Passwort zurücksetzen
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default ResetPasswordForm;
