import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getFachGroupDetail, getBreeders, updateFgBreeders, getAllCrops, updateFgCrops, updateFg, deleteFachGroup } from '../util/APIUtils';
import { UserOutlined, ProfileTwoTone, TabletTwoTone } from '@ant-design/icons';
import PickBreeder from '../farmprofile/locations/PickBreeder';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import {
    Button,
    Modal,
    Input,
    Card,
    Descriptions,
    Row,
    List,
    Col,
    Typography,
    PageHeader,
    Tag,
    Space

} from 'antd';
import TaskList from '../workflow/TaskList';
import PickCrop from '../crops/PickCrops';
import DeleteWithConfirm from '../common/DeleteWithConfirm';
import BreederSelect from '../common/BreederSelect';
import openNotification from '../common/openNotification';

class FachGroupDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            isLoading: false,
            fgDetail: {},
            pickBreedervisible: false,
            pickCropVisible: false,
            breeders: [],
            openModal: false,
            crops: [],
            selectedBreeders: [],
            selectedCrops: [],
            currentUser: props.currentUser,

        };
    }

    showModal = () => {
        this.setState({
            openModal: true
        })
    };

    handleCancel = () => {
        this.setState({
            openModal: false
        })
      };

    geFgDetailsById(id) {
        console.log('Loading FG detail')
        let promise;
        promise = getFachGroupDetail(id);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            this.loadBreeders();
            let vals = [];
            let valsCrops = [];
            for (var item of response.fachGroupMembers) {
                vals.push(item.id);
            }

            for (var item1 of response.cropInfoList) {
                valsCrops.push(item1.id);
            }
            this.setState({
                fgDetail: response,
                isLoading: false,
                selectedBreeders: vals,
                selectedCrops: valsCrops
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        const { id } = this.props.match.params
        this.geFgDetailsById(id);
    }

    componentDidUpdate(nextProps) {
        const { id } = nextProps.match.params
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                isLoading: false,
            });

            this.geFgDetailsById(id);
            // this.loadBreeders();
        }
    }

    loadCrops() {
        console.log('Loading Crops')
        let promise;
        promise = getAllCrops();

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {

            let vals = [];

            for (var item of response) {
                vals.push({
                    'id': item.id,
                    'name': item.name
                });
            }
            this.setState({
                crops: vals,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadBreeders() {
        console.log('Loading Breeders')
        let promise;
        promise = getBreeders();

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            let vals = [];
            for (var item of response) {
                vals.push({
                    'id': item.id,
                    'fullName': item.firstName + " " + item.lastName
                });
            }
            this.loadCrops();
            this.setState({
                breeders: vals,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    delFachGroup(id) {
        console.log('Deleting FG')
        let promise;
        promise = deleteFachGroup(id, 1);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            
            this.setState({
                isLoading: false
            })

            openNotification("success", "Fachgruppe gelöscht!")
            this
            .props
            .history
            .goBack();

        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }


    setBreedersFromModal = breeders => {
        console.log(breeders);
        let vals = [];
        let promise;

        for (var item of breeders) {
            vals.push({
                "fgId": this.state.fgDetail.id,
                "breederId": item,
                "leader": 0
            });
        }

        console.log(vals);
        promise = updateFgBreeders(this.state.fgDetail.id, vals);
        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            this.geFgDetailsById(this.state.fgDetail.id);
            this.setState({
                pickBreedervisible: false,
                isLoading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({ isLoading: false })
        });
    }

    setCropsFromModal = crops => {
        let vals = [];
        let promise;

        for (var item of crops) {
            vals.push({
                "fgId": this.state.fgDetail.id,
                "cropId": item,
            });
        }

        promise = updateFgCrops(this.state.fgDetail.id, vals);
        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            this.geFgDetailsById(this.state.fgDetail.id);
            this.setState({
                pickCropVisible: false,
                isLoading: false
            })
        }).catch(error => {
            console.log(error);
            this.setState({ isLoading: false })
        });
    }

    reload = () => {
        console.log("Reload")
        this.setState({ isLoading: false })
    }

    actionClicked = () => {
        console.log("Action")
        this.props.history.push('/fg-tasks/' + this.state.fgDetail.id);
    }

    handleBack = (e) => {
        this
            .props
            .history
            .goBack();
    };

    onEdit = (val) => {
        this.setState(
            { editing: val }
        )
    }

    onInputchange = (event) => {
        this.setState({ fgDetail: { ...this.state.fgDetail, [event.target.name]: event.target.value } });
    }

    onInputchangeOther = (event) => {
        this.setState({ fgDetail: { ...this.state.fgDetail, ["type"]: event } });
    }

    setFgLeader = (val) => {
        this.setState({
            fachGroupLeaderId: val
        })
    }

    onSave = (values) => {
        let promise;

        console.log(this.state.fgDetail)

        let fgInfo = {
            "id": this.state.fgDetail.id,
            "name": this.state.fgDetail.name,
            "description": this.state.fgDetail.description,
            "fachGroupLeaderId": this.state.fachGroupLeaderId ? this.state.fachGroupLeaderId : this.state.fgDetail.fachGroupLeaderId
        }

        this.setState({ isLoading: true });
        promise = updateFg(fgInfo);
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.geFgDetailsById(this.state.fgDetail.id);
            this.setState({
                isLoading: false,
                editing: false
            })
        }).catch(error => {
            console.log(error);
            openNotification("error", "error")
            this.setState({ isLoading: false, editing: false })
        });
    }


    render() {
        const { t } = this.props;
        let thisFgLeader = this.state.fgDetail.fachGroupLeaderId === this.state.currentUser.breederInfo.id;
        let officeUser = this.state.currentUser.officeUser;
        let boardMember = this.state.currentUser.boardMember;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    extra={[
                        <Space>
                            {/* {thisFgLeader || officeUser || boardMember ?
                                <Button key="334" type="primary" onClick={this.actionClicked}>Aktionen</Button>
                                : ""} */}
                            {this.state.editing ?
                                <Space>
                                    <Button key="334" type="primary" onClick={this.onSave}>{t("general.save")}</Button>
                                    <Button key="334" onClick={() => this.onEdit(false)}>{t("general.cancel")}</Button>
                                </Space>
                                :
                                <Space>
                                    <Button key="334" type="primary" onClick={() => this.onEdit(true)}>{t("general.edit")}</Button>
                                    <DeleteWithConfirm confirm={this.showModal} />
                                </Space>}
                        </Space>]}
                    title={t("fachgroup.fg_details")}
                    subTitle={this.state.fgDetail.fachGroupName} />

                <Modal
                    title="Löschen"
                    visible={this.state.openModal}
                    onOk={() => this.delFachGroup(this.state.fgDetail.id)}
                    confirmLoading={this.state.isLoading}
                    onCancel={this.handleCancel}
                >
                    <p>Abhängigkeiten vorhanden, möchten Sie sie löschen?</p>
                </Modal>

                <Card bordered={true} loading={this.state.isLoading} headStyle={{ padding: "0" }}>
                    <Descriptions
                        size="small"
                        column={2}
                        bordered="true"
                        style={{
                            marginBottom: 20
                        }}>
                        <Descriptions.Item label={t("fachgroup.fg_name")} >
                            {this.state.editing ? <Input name='name' defaultValue={this.state.fgDetail.name} onChange={this.onInputchange} /> : <b>{this.state.fgDetail.name}</b>}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("fachgroup.fg_description")}>
                            {this.state.editing ? <Input name='description' defaultValue={this.state.fgDetail.description} onChange={this.onInputchange} /> : this.state.fgDetail.description}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label={t("fachgroup.fg_description")}>{this.state.fgDetail.cropName}</Descriptions.Item> */}
                        {/* <Descriptions.Item label={t("crops.crop")}>{this.state.fgDetail.cropShortName}</Descriptions.Item> */}
                        {/* <Descriptions.Item label={t("crops.crop_group")}>{this.state.fgDetail.cropGroup}</Descriptions.Item> */}
                        <Descriptions.Item label={t("fachgroup.fg_leader_name")}>
                            {this.state.editing ?
                                <BreederSelect setItem={this.setFgLeader} selectedItem={this.state.fachGroupLeaderId ? this.state.fachGroupLeaderId :
                                    this.state.fgDetail.fachGroupLeaderId} /> : <Space>{this.state.fgDetail.fachGroupLeader} <Tag color="green">Leiter</Tag></Space>}

                        </Descriptions.Item>
                        {/* <Descriptions.Item label={t("fachgroup.fg_leader_short")}>{this.state.fgDetail.fachGroupLeaderShortName}</Descriptions.Item> */}

                    </Descriptions>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                            <Card bordered={true} title={t("fachgroup.fg_members")} extra=
                                {[officeUser ? <PickBreeder
                                    modalVisible={this.state.pickBreedervisible}
                                    setBreeders={this.setBreedersFromModal}
                                    breederList={this.state.breeders.filter(x => x.id != this.state.fgDetail.fachGroupLeaderId)}
                                    onExit={this.reload}
                                    existingBreeders={this.state.selectedBreeders} /> : ""]} >

                                <List
                                    dataSource={this.state.fgDetail.fachGroupMembers}
                                    renderItem={item => {
                                        let linkText = `/breeders/profile/${item.id}/0`;
                                        return (<List.Item key={"br" + item.id}
                                            extra={item.id === this.state.fgDetail.fachGroupLeaderId ? <Tag color="green">Leader</Tag> : ""}>
                                            <List.Item.Meta
                                                avatar={

                                                    <UserOutlined />
                                                }
                                                // title={<a href={linkText}>{item.shortName}</a>}
                                                title={<Link
                                                    to={{
                                                        pathname: linkText,
                                                    }}>{item.shortName}</Link>}
                                                description={item.firstName + " " + item.lastName}
                                            />
                                        </List.Item>)
                                    }} />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card bordered={true} title={t("general.crops")} extra=
                                {[officeUser ? <PickCrop
                                    modalVisible={this.state.cropVisible}
                                    setCrops={this.setCropsFromModal}
                                    cropList={this.state.crops}
                                    onExit={this.reload}
                                    existingCrops={this.state.selectedCrops} /> : ""]} >

                                <List
                                    dataSource={this.state.fgDetail.cropInfoList}
                                    renderItem={item => {
                                        let linkText = `/crop/detail/${item.id}`;
                                        return (<List.Item key={"br" + item.id}>
                                            <List.Item.Meta
                                                avatar={
                                                    <ProfileTwoTone />
                                                }
                                                // title={<a href={linkText}>{item.shortName}</a>}
                                                title={<Link
                                                    to={{
                                                        pathname: linkText,
                                                    }}>{item.shortName}</Link>}
                                                description={item.name}
                                            />
                                        </List.Item>)
                                    }} />
                            </Card>
                        </Col>

                    </Row>
                    {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                            <Card bordered={true} title="FG Tasks" >
                                <TaskList groupId={this.state.fgDetail.id} />
                            </Card>
                        </Col>

                    </Row> */}
                </Card>

            </div>
        )
    }
}

export default withTranslation()(withRouter(FachGroupDetail));
