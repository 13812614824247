import React, { useState } from 'react';
// import { Modal, Card, Descriptions, Select, Form, Button, Space } from 'antd'
import {
    Button,
    Modal,
    Cascader,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Switch,
    TreeSelect,
    Space,
  } from 'antd';
import item from 'react-bootstrap-typeahead/lib/behaviors/item';
import { EditTwoTone } from '@ant-design/icons';
import { currencyFormatter, currencyParser } from '../../util/Helpers';

function EditItemExamForm(props) {
    console.log(props)
    const [visible, setVisible] = useState(false);
    const [otherVisible, setOtherVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [form] = Form.useForm();
    const isPlanEditable = props.isPlanEditable
    const isReportEditable = props.isReportEditable
    const item = props.item;

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = (val) => {
        console.log(val)
        setModalText('The modal will be closed after two seconds');
        console.log(props)

        let obj=  {
                "key": (Math.random() * 1000000).toFixed(0),
                "id": item.id,
                "examinationItem": otherVisible ? val.customItem : "",
                'projectDetailId': props.projectDetailId,
                'projectId': props.projectId,
                'examinationItemId': !otherVisible ? val.item: 0,
                'quantityBudget': val.quantityBudget,
                'amountBudget': val.amountBudget,
                'quantityActual' : val.quantityActual,
                'amountActual' :  val.amountActual,
                'customItem':  val.customItem,
                'customType': otherVisible ? 1 : 0,
                'actual':  isReportEditable ? 1 :0,
                'actualSelected': isReportEditable ? 1 :0,
                'budget': isPlanEditable ? 1 :0
        };

        console.log(obj)
        props.saveItem(obj, true)
        // props.setDataSource(props.dataSource.push({
        //         'key': "key1",
        //         "accountingItem": "Pflanzung 22" ,
        //         'projectDetailId': 1,
        //         'projectId': 1,
        //         'accountingItemId': 1,
        //         'daysResearcher': 22.0,
        //         'daysHelper': 12.0,
        //         // 'daysResearcherActual' : element.daysResearcherActual ? parseFloat(element.daysResearcherActual) : 0.0,
        //         // 'daysHelperActual' : element.daysHelperActual ? parseFloat(element.daysHelperActual) : 0.0,
        //         // 'customItem': element.accountingItemId == 0 ? element.accountingItem : "",
        //         // 'customType': element.accountingItemId == 0 ? 1 : 0,
        //         // 'actual': element.daysHelperActual || element.daysResearcherActual ? 1 : 0,
        //         'actualSelected': 0,
        //         'budget': 1
            
        // }))
        setConfirmLoading(true);
        setTimeout(() => {
            setVisible(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const onChange = (val) => {
        setOtherVisible(!otherVisible);
    }

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };


    return (
        <>
            <Space><Space>
                <EditTwoTone onClick={showModal} />
                {/* <Button style={{"marginLeft": "30px;"}} type="primary" onClick={showModal}>
            Artikel hinzufügen
            </Button> */}
            </Space></Space>
            <Modal
                title="Untersuchungen/Saatgutdiagnostik"
                visible={visible}
                width={720}
                onOk={() => {
                    console.log(form.getFieldsValue());
                    form
                      .validateFields()
                      .then((values) => {
                        form.resetFields();
                        handleOk(values);
                      })
                      .catch((info) => {
                        console.log('Validate Failed:', info);
                      });
                  }}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    onFinish={handleOk}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 26 }}
                    layout="horizontal"
                    initialValues={{
                        otherCheck: item.examinationItemId,
                        customItem: item.examinationItem,
                        quantityBudget: item.quantityBudget,
                        amountBudget: item.amountBudget,
                        quantityActual: item.quantityActual,
                        amountActual: item.amountActual,
                      
                    }}
                    // initialValues={{  }}
                >
                     <Form.Item label="Sonstiges" valuePropName="checked">
                        <Switch onChange={onChange} />
                    </Form.Item>
                    {!otherVisible ?
                    <Form.Item name="customItem" label="Untersuchungen">
                        <Input disabled/>
                    </Form.Item> :
                    <Form.Item name="item" label="Untersuchungen">
                        <Select>
                            {props.items.map(item => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>}
                  
                    <Form.Item name="quantityBudget" label="Menge-Kalkulation">
                    <InputNumber
                            disabled={!isPlanEditable}
                            formatter={currencyFormatter}
                            parser={currencyParser}
                        />
                    </Form.Item>
                    <Form.Item name="quantityActual" label="Menge-Abrechnung">
                    <InputNumber
                            disabled={!isReportEditable}
                            formatter={currencyFormatter}
                            parser={currencyParser}
                        />
                    </Form.Item>
                    <Form.Item name="amountBudget" label="Einzelpreis-Kalkulation" >
                    <InputNumber
                            disabled={!isPlanEditable}
                            formatter={currencyFormatter}
                            parser={currencyParser}
                        />
                    </Form.Item>
                    <Form.Item name="amountActual" label="Einzelpreis-Abrechnung">
                    <InputNumber
                            disabled={!isReportEditable}
                            formatter={currencyFormatter}
                            parser={currencyParser}
                        />
                    </Form.Item>

                    

                </Form>
            </Modal>
        </>
    );
};


export default EditItemExamForm