import React, { Component } from 'react'
import { Image, List, Avatar, Card, Divider, Button } from 'antd';
import { getFileListsForProjectId, getMediaPath } from '../../util/APIUtils';
import { FileExcelFilled, FilePdfFilled, FileWordFilled, Loading3QuartersOutlined } from '@ant-design/icons';
import LoadingIndicator from '../../common/LoadingIndicator';
import UploadImageToS3WithNativeSdk from '../../common/UploadImageToS3WithNativeSdk';
import UploadFiles from '../../common/UploadFiles';

export class ProjectResources extends Component {

    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            projectId: props.projectId,
            path: "",
            year: props.year,
            fileList: [],
            fileListImages: [],
            fileListDocs: [],
            reload: false,

        };
    }

    fetchFiles = () => {

        this.setState({
            loading: true,
        })

        let promise;
        promise = getFileListsForProjectId(this.state.projectId);
        if (!promise) {
            return;
        }

        promise.then(response => {

            console.log(response)
            this.setState({
                fileList: response,
                fileListImages: response.filter(a => a.type == 1),
                fileListDocs: response.filter(a => a.type == 2),
                loading: false,
                reload: false

            })
            console.log(this.state.fileList)
            this.getPath();


        }).catch(error => {
            this.setState({ loading: false })
        });

    }

    getPath = () => {

        this.setState({
            loading: true,
        })

        let promise;
        promise = getMediaPath(this.state.year, this.state.projectId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            this.setState({
                path: response.path,
                loading: false

            })

            console.log(response)


        }).catch(error => {
            console.log(error)
            this.setState({ loading: false })
        });

    }

    componentDidMount() {
        this.fetchFiles(this.state.projectId);
        
    }

    getFileNameDoc = (path) => path.substring(
        path.indexOf("docs/") + 5,
        path.lastIndexOf("?X-Amz")
    );

    getFileNameImg = (path) => path.substring(
        path.indexOf("bilder/") + 7,
        path.lastIndexOf("?X-Amz")
    );

    getFileExtension = (fileName) => fileName.split('.').pop()

    getIconForExtension = (ext) => {

        if (['xls', 'xlsx'].includes(ext)) return <FileExcelFilled />
        if (['doc', 'docx'].includes(ext)) return <FileWordFilled />
        if (['pdf',].includes(ext)) return <FilePdfFilled />

    }

    refreshPage = () => {
        console.log('Rfresh');
        this.fetchFiles(this.state.projectId)
        // this.setState(
        //   {reload: true},
        //   () => this.setState({reload: false})
        // )
      }

    render() {
        return (
            <div>

                {this.state.loading ? <LoadingIndicator /> :
                    <div>
                        <Card title="Bilder" bordered>
                        {this.state.fileListImages.map((item) => (
                            <Image
                                alt={this.getFileNameImg(item.url)}
                                width={200}
                                style={{ 'padding': '2px' }}
                                src={item.url}
                                
                            />
                        ))}
                        <br></br>
                         {/* <UploadImageToS3WithNativeSdk path={this.state.path} fileType={"bilder"} refreshPage={this.refreshPage}/> */}
                         <UploadFiles path={this.state.path} fileType={"bilder"} refreshPage={this.fetchFiles}/>
                        </Card>
                        <Card  title="Sonstige Dokumente" bordered>
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.fileListDocs}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        key={item.url}
                                        avatar={<Avatar shape="square" icon={this.getIconForExtension(this.getFileExtension(this.getFileNameDoc(item.url)))} />}
                                        title={<a href={item.url}>{this.getFileNameDoc(item.url)}</a>}
                                    // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>
                            )}
                        />
                         <UploadFiles path={this.state.path} fileType={"docs"} refreshPage={this.fetchFiles}/>
                         </Card>
                        {/* <UploadImageToS3WithNativeSdk path={this.state.path} fileType={"docs"} refreshPage={this.fetchFiles}/> */}
                    </div>}
            </div>
        )
    }
}

export default ProjectResources