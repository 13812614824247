import React from 'react'
import { Upload, Button } from 'antd'
import { uploadFile } from '../util/APIUtils';

function UploadFiles(props) {

    const [loading, setLoading] = React.useState(false)

    const uploadFileNew = (f) => {
        let file = f.file.originFileObj;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', props.path + "/" + props.fileType + "/" + file.name)

        console.log({ formData })
        let promise;
        setLoading(true)
        promise = uploadFile(formData, file.name);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            props.refreshPage();
            setLoading(false)
            return;

        }).catch(error => {
            console.log(error)
            setLoading(false)
            props.refreshPage();
            return;
        });

    }

    return (
        <div>
            <Upload.Dragger
                multiple
                // listType='picture'
                onChange={uploadFileNew}
            >
                Datei ziehen
                Oder <br></br>
                <Button >hochladen</Button>
            </Upload.Dragger>
        </div>
    )
}

export default UploadFiles