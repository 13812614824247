import React from 'react'
import { useTranslation } from 'react-i18next';

import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Button,
    Input,
    List,
    Row,
    Col,
    Checkbox,
    Form,
    Skeleton,
    PageHeader,
    Space,
    notification
} from 'antd';
import { updateUserInfo } from '../../util/APIUtils';

function UserInfo(props) {

    console.log(props)
    const userInfo = props.userInfo;
    const userProfileId = props.id;
    const breederId = props.breederId;
    const [loading, setLoading] = React.useState(false)
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [editing, setEditing] = React.useState(false);
    const [active, setActive] = React.useState(userInfo.active)

    let initVals = {}
    if (userInfo) {
        initVals = {
            id: userInfo.id,
            phone: userInfo.phone,
            email: userInfo.email,
            houseNo: userInfo.houseNo,
            address: userInfo.address,
            fax: userInfo.fax,
            active: userInfo.active
        }
    }

    const onActiveChange = (e) =>  {
        console.log(`checked = ${e.target.checked}`);
        setActive(e.target.checked)
    };

    const onFinish = (obj) => {
        console.log(obj);
        let userObj = {
            id: userProfileId,
            phone: obj.phone,
            email: obj.email,
            houseNo: obj.houseNo,
            address: obj.address,
            fax: obj.fax,
            active: active ? 1 :0,
            breederInfo: {
                id: breederId
            }
        }

        console.log(userObj)
        let promise;
        promise = updateUserInfo(userObj);

        if (!promise) {
            return;
        }

        setLoading(true)

        promise.then(response => {
            setLoading(false);
            setEditing(false)

            props.loadBreederInfo()
            if (response) {
                notification.success({ message: "Kultursaat", description: "Saved", placement: 'topLeft' })
            } else {
                throw 500
            }

        }).catch(error => {
            console.log(error)
            setLoading(false)
            notification.error({ message: "Kultursaat", description: "Failed", placement: 'topLeft' })
        });
    }

    return (
        <div>
            <Form name="user-form" form={form} onFinish={onFinish} initialValues={initVals} >
                <Descriptions
                    title={t("breeders.breeder_profile")}
                    size="small"
                    bordered="true"
                    style={{
                        marginBottom: 20

                    }}
                    extra={!editing ?
                        <Button onClick={() => setEditing(true)}>Bearbeiten</Button> :
                        <Space>
                            <Button type='primary' key="35" onClick={() => form.submit()}>Speichern</Button>
                            <Button onClick={() => setEditing(false)}>Abbrechen</Button>
                        </Space>}>
                    <Descriptions.Item label={"Nachname"} >
                        <b> {userInfo.lastName} </b>
                    </Descriptions.Item>
                    <Descriptions.Item label={"Vorname"} >
                        <b>{userInfo.firstName}  </b>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("general.short_name")} >
                        {userInfo.shortName}
                    </Descriptions.Item>

                    <Descriptions.Item label={"Telefon-Nr."} span={2}>
                        {editing ?
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte eingeben ' + t("general.phone"),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item> :

                            userInfo.phone
                        }
                    </Descriptions.Item>

                    <Descriptions.Item label={"Fax"} span={2}>
                        {editing ?
                            <Form.Item
                                name="fax"
                            >
                                <Input />
                            </Form.Item> :

                            userInfo.fax
                        }
                    </Descriptions.Item>

                    <Descriptions.Item label={"E-Mail"} span={2}>
                        {editing ?
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte eingeben ' + t("general.email"),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item> :

                            userInfo.email
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="Active">
                                {editing ?
                                    <Form.Item
                                        name="active"
                                    >
                                        <Checkbox onChange={onActiveChange} defaultChecked={userInfo.active}></Checkbox>
                                    </Form.Item> :

                                    
                                    <Checkbox disabled defaultChecked={userInfo.active}></Checkbox>
                                }
                            </Descriptions.Item>
                </Descriptions>
                <Divider
                    style={{
                        marginBottom: 20
                    }} />
                <Descriptions
                    title={t("general.address")}
                    size="small"
                    bordered="true"
                    style={{
                        marginBottom: 20
                    }}>
                    {/* <Descriptions.Item label="House No" span={3}>
                        {editing ?
                            <Form.Item
                                name="houseNo"
                            >
                                <Input />
                            </Form.Item> :

                            userInfo.houseNo
                        }
                    </Descriptions.Item> */}

                    <Descriptions.Item label={t("general.address")}>
                        {editing ?
                            <Form.Item
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte eingeben ' + t("general.address"),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item> :

                            userInfo.address
                        }
                    </Descriptions.Item>


                </Descriptions>
            </Form>
        </div>
    )
}

export default UserInfo
