import React, { useEffect } from 'react'
import { Table, Layout, AutoComplete, Button, Col, PageHeader, Card, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import CropSearch from '../../crops/CropSearch';
import { getAllBreeders } from '../../util/APIUtils';
import { Link, useHistory  } from 'react-router-dom';

function UserManagement(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false)
    const [userList, setUserList] = React.useState([]);
    const currentUser = props.currentUser;
    console.log(props)

    
    const loadBreederList = React.useCallback(async () => {
        let promise;
        promise = getAllBreeders(0, 0);
        if (!promise) {
            return;
        }

        setLoading(true)
        promise.then(response => {
            console.log(response)
            setUserList(response)
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])


    useEffect(() => {
        loadBreederList();
    }, [loadBreederList]);

    const onSelect = (val)  => {
        console.log(val)
        let linkText = `/user-profile/${val}`
        props.history.push(linkText);
    }

    const columns = [
        {
            title: t("general.name"),
            dataIndex: 'fullName',
            key: 'fullName',
            render: (text, record) => {
                let linkText = `/user-profile/${record.userProfileId}`
                let fullName = `${record.firstName} ${record.lastName}`
                return (
                    <span><b>
                        <Link
                            to={{
                                pathname: linkText,
                                state: {
                                    id: record.id
                                }
                            }}>{fullName}</Link>
                    </b>
                    </span>
                )
            },
            width: 250,
            sorter: (a, b) => {
                if (a.fullName < b.fullName) {
                    return -1;
                }
                if (a.fullName > b.fullName) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ['descend', 'ascend']
        }
        ,{
            title: "Kurzer Name",
            dataIndex: 'shortName',
            key: 'shortName',
            ellipsis: true,
            width: 100
        },,{
            title: t("general.address"),
            dataIndex: 'address',
            key: 'address',
            ellipsis: true
        },  {
            title: t("general.phone"),
            dataIndex: 'phone',
            key: 'phone',
            ellipsis: true
        }, {
            title: "Email/Username",
            dataIndex: 'email',
            key: 'email',
            ellipsis: true
        },
        {
            title: "Active",
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => {
                return text ? "Ja" : "Nein"
           },
           filters: [
            {
                text: 'Ja',
                value: true,
            },
            {
                text: 'Nein',
                value: false,
            },
        ],
        onFilter: (value, record) => record.active == value,
        }
    ];

    
    let bList = userList.map(a => ({ "value": a.firstName + " "  + a.lastName, "key": a.id }));
    let placeHolder = `${bList.length} Benutzer`
    // let officeUser = currentUser.roles.find(x => x.name === 'ROLE_OFFICE');
    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Benutzer"
                subTitle=""
                extra={[ <Link to="/breeder/add"><Button key="3" type="primary" >{t("general.add")}</Button></Link>]}
            />
            <Card bordered={true} loading={loading}
                
            >
                <Table
                    columns={columns}
                    dataSource={userList}
                    loading={loading} 
                    pagination={false}
                    />
            </Card>
        </div>

    );
}


export default UserManagement