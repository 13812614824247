import React, { Component } from 'react'
import {
    Card, notification, Menu, Breadcrumb,
    PageHeader, Form, Input,
    Cascader, Select, Row, Col, Checkbox,
    Button, AutoComplete, Space
} from 'antd';
// import {PlaceSearch} from '../common/PlaceSearch';
import { withRouter } from 'react-router-dom';
import GeoSuggest from 'antd-geosuggest';
import { createFarmLocation, getAllFarmProfiles } from '../../util/APIUtils';
import { withTranslation  } from "react-i18next";
import {POLL_LIST_SIZE} from '../../constants'
import '../FarmProfile.css';


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};
const formItemLayout = {
    labelCol: {
       span: 8 
    },
    wrapperCol: {
       span: 8 
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
  };

class NewFarmLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            farmLocation: null,
            farmProfiles: [],
            isLoading: false,
            visible: false
        };
    }

    loadFarmProfileList(page = 0, size = POLL_LIST_SIZE) {
        let promise;
        promise = getAllFarmProfiles(page, size);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                farmProfiles: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        this.loadFarmProfileList();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                farmProfiles: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadFarmProfileList();
        }
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    formRef = React.createRef();

    onFinish = (values) => {
        const flObj = {
            "name": values.locationName,
            "lat": values.lat,
            "lon": values.lng,
            "poly": values.poly || "",
            "farmprofileId": values.farmProfileId,
            "primaryLocation": values.primaryLocation ? 1: 0
        }
        console.log(flObj);
        createFarmLocation(flObj)
        .then(response => {
            notification.success({
                message: 'Kultursaat',
                description: response
            }); 
            this.props.history.push("/farmprofile/locations");
        }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login ');    
            } else {
                notification.error({
                    message: 'Kultursaat',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });              
            }
        });

        
    };
    onReset = () => {
        this.formRef.current.resetFields();
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={t("farmprofile.new_farm_location")}
                    subTitle=""
                    extra={[]
                    }
                />
                <Card bordered={true} >
                    <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                        <Form.Item name="locationName" label={t("farmprofile.location_name")} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="lat" label="Lat" rules={[{ required: true }]}>
                        <Input />
                        </Form.Item>
                        <Form.Item name="lng" label="Lng" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="poly" label="Poly" rules={[{ required: false }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="farmProfileId" label={t("farmprofile.farm_profile")} rules={[{ required: true }]}>
                            <Select >
                                {this.state.farmProfiles.map(fbb => 
                                    <option key={fbb.id} value={fbb.id}>{fbb.location}</option>
                                    )}
                            </Select>
                        </Form.Item>
                        <Form.Item name="primaryLocation" valuePropName="checked" label={t("farmprofile.primary")} 
                            tooltip={t("farmprofile.location_is_primary")}>
                            <Checkbox/>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                            {t("general.submit")}
                            </Button>
                            <Button htmlType="button" onClick={this.onReset}>
                            {t("general.reset")}
                            </Button>
                        </Form.Item>
                    </Form>

                </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(NewFarmLocation));
