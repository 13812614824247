import React, { Component } from 'react';
import { Table, Layout, AutoComplete, Button, Col, PageHeader, Card, Drawer } from 'antd';
const { Header, Content, Footer, Sider } = Layout;
import { Link } from 'react-router-dom';
import { getAllFarmProfiles } from '../util/APIUtils';
import { isOfficeUser } from '../util/Helpers';
import { withRouter } from 'react-router-dom';
import { POLL_LIST_SIZE } from '../constants';
import { withTranslation } from "react-i18next";
import FarmProfileSearch from './FarmProfileSearch';
import './FarmProfile.css';
import UploadImageToS3WithNativeSdk from '../common/UploadImageToS3WithNativeSdk';
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


class FarmProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            farmprofiles: [],
            page: 0,
            size: 10,
            totalElements: 0,
            totalPages: 0,
            last: true,
            isLoading: false,
            visible: false,
            currentUser: props.currentUser
        };
    }

    loadFarmProfileList(page = 0, size = POLL_LIST_SIZE) {
        let promise;
        promise = getAllFarmProfiles(page, size);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            const breeders = [];
            this.setState({
                farmprofiles: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        this.loadFarmProfileList();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                farmprofiles: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadFarmProfileList();
        }
    }

    onSelect = (val) => {
        let linkText = `/farmprofile/detail/${val}`
        this.props.history.push(linkText);
    }

    render() {
        const { t } = this.props;
        let officeUser = isOfficeUser(this.state.currentUser)
        const columns = [
            {
                title: t("general.name"),
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    let linkText = `/farmprofile/detail/${record.id}`
                    return (
                        <span><b>
                            <Link
                                to={{
                                    pathname: linkText,
                                    state: {
                                        id: record.id
                                    }
                                }}>{text? text: "null"}</Link>
                        </b>
                        </span>
                    )
                },
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                },
                width: 300
            },
            {
                title: t("general.address"),
                dataIndex: 'address',
                key: 'address',
                width: 550,
                sortDirections: ['descend', 'ascend']
            },
            {
                title: t("general.email"),
                dataIndex: 'email',
                key: 'email',
                width: 300
            },
            {
                title: t("general.phone"),
                dataIndex: 'phone',
                key: 'phone',
                width: 300
            }
        ];

        let bList = this
            .state
            .farmprofiles
            .filter(ab => ab.name !== "")
            .map(a => ({ "value": a.name, "key": a.id }));

        let placeHolder = `${bList.length} ${t("farmprofile.farm_profiles")}`
        console.log(bList);
        return (
            this.props.isAuthenticated && <div>
                <PageHeader
                    className="site-page-header"
                    title={t("farmprofile.farm_profiles")}
                    subTitle=""
                    extra={[
                        officeUser? <Link to="/farmprofile/new"><Button key="3" type="primary" >{t("general.add")}</Button></Link>: ""
                    ]
                    }

                />
                 <Card bordered={true} loading={this.state.isLoading}
                    title={bList.length > 0 ?
                        <FarmProfileSearch dataList={bList}
                            dataCount={bList.length}
                            placeHolder={placeHolder}
                            onSelect={this.onSelect} /> : ""}
                >
                    <Table
                        columns={columns}
                        dataSource={this.state.farmprofiles}
                        loading={this.state.isLoading} 
                        pagination={
                            { defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ['50', '100', '250']}}
                        />
                </Card>

               
            </div>

        );
    }
}
export default withTranslation()(FarmProfile);
